
/************* jumbtrons css **********/

.jumboHome {
  background-image: url('./images/homePhoto6.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
 
  /* @media (max-width: @iphone-screen) {
    background-attachment: scroll;
  } */
  
}

.jumboAbout {
  /* background-image: url('./images/aboutImage8.jpeg'); */
  /* max-height: 300vh;
  min-height: 100vh;
  background-position:center;
  background-size:cover; */

  background-image: url('./images/aboutImage8.jpeg');
  background-color: transparent;
  margin-bottom: 0px !important;
  /* min-height: 95vh; */
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: lightgrey; */
}

/************* navbar css **********/
.nav-link {
  font-family: 'Roboto', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
    sans-serif;
  color: white !important;
  font-size: 1.3em;
  margin-right: 10px; 
} 

.white-color {
  color: white;
}

/* .navbar-brand {
  /* width: 100%;
  left: 0;
  top: 0;
  display: block;
  left: 30%;
  top: 0; 
  text-align: center; 
  margin: auto;
  color: white !important;
  font-family: 'Merriweather', 'Gill Sans', 'Gill Sans MT', Calibri,
    'Trebuchet MS', sans-serif;
  transform: translatex(-10%);
} */
/* .navbar-collapse {
  float: none;
  display: inline-block;
  vertical-align: top;
} */

/* .navbar {
  max-height: 200px;
} */
/* .nav {
  margin-right: -350px;
}   */

.navbar-custom {
  background-color: rgba(71, 42, 42, 0.685);
}


/************* cards css **********/
.card {
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: lightsteelblue !important;
  /* transition: 0.3s; */
  margin-top: 4rem;
  height: 400px;
  /* min-width: 170px; */

  overflow-y: auto;
  background-image: url('./images/cardImage4.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: 'no-repeat';
}
.about-card {
  height: 200px;
}

card.new {
  background-image: url('./images/homePhoto1.jpeg');
}
.card-text {
  word-wrap: break-word;
}

.card.threading {
  background-image: url('./images/CardImage2.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: 'no-repeat';
}

.card-Title {
  color: brown;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.rainbow-lr {
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  color: transparent;
  background-clip: text;
}
.rainbow-td {
  background: linear-gradient(
    to bottom,
    red,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  color: transparent;
  background-clip: text;
}

a.instagram {
  color: black;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.twitter {
  color: #49a1eb;
}


ul li {
  font-size: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;

  /* color: whitesmoke; */
}

hr.new5 {
  border: 2px solid whitesmoke;
  border-radius: 5px;
  width: 100%;
}

.font-awesome {
  margin-right: 10px;
}







.main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.col-center-block {
  float: none;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 10rem;
  /* margin-left: auto; margin-right: auto; */
}






.pText{
  font-family:'Times New Roman', Times, serif;
  font-size:larger;
  font-weight: 500;
  color: rgb(25, 12, 12);
}
.aboutSubheading{
  font-family:'Times New Roman', Times, serif;
  font-size:larger;
  font-weight: 700;
  color: rgb(77, 38, 38);
  text-align: center;
}
.socialText {
  margin-top: 5px;
  font-family:'Times New Roman', Times, serif;
  font-size:large;
  font-weight: 800;
  color: rgb(77, 38, 38);
  text-align: center;
}
.contactBookingText{
  font-family:'Times New Roman', Times, serif;
  font-size:x-large;
  text-align: center;
  font-weight: 700;
  color: rgb(77, 38, 38);
  margin-top: -30px;
}
.contactLocationText{
  font-family:'Times New Roman', Times, serif;
  font-size:large;
  text-align: center;
  font-weight: 600;
  color: rgb(77, 38, 38);
}
.contactLocationHeading{
  font-family:'Times New Roman', Times, serif;
  font-size:x-large;
  text-align: center;
  font-weight: 500;
  color: rgb(77, 38, 38);
}
.contactHours{
  font-family:'Times New Roman', Times, serif;
  font-size:medium;
  text-align: center;
  font-weight: 500;
  color: rgb(111, 76, 76)
}

/** maps div css */
.map-div {
  height: 50px;
  width: 50%;
}
.map-responsive {
  /* left: 0;
  top: 0;
  height: 100%;
  width: 100%;*/
  /* position: absolute; */
  overflow: hidden;
  margin-top: 10rem;
}
#map {
  width: 100%;
  height: 430px;
}
.map-canvas {
  min-width: 200px;
  width: 70%;
  min-height: 300px;
  height: 40%;
  margin: 0 auto;
  margin-top: -180px;

  /* border: 1px solid blue; */
}
.map-canvas2 {
  align-self: center;
  display: inline-block;
  min-width: 200px;
  width: 400px;
  min-height: 200px;
  height: 30%;
  margin-right: 100px;
 
  /* border: 1px solid blue; */
}






body {
  background-color: #fcfcfc;
}

.columns {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0;
}

.left-div {
  display: inline-block;
  width: 400px;
  min-width: 300px;
  max-width: 400px;
  min-height: 200px;
  text-align: left;
  padding: 10px;
  background-color: #ddd;
  border-radius: 3px;
  margin: 5px;
  vertical-align: top;
}

.right-div {
  display: inline-block;
  width: 400px;
  min-width: 300px;
  max-width: 400px;
  min-height: 200px;
  text-align: left;
  padding: 10px;
  background-color: #ddd;
  border-radius: 3px;
  margin: 5px;
}

.left-text,
.right-text {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .left-div,
  .right-div {
    max-width: 100%;
    /* Additional */
    width: 100%;
    display: block;
  }
}

/** Footer CSS */
footer { 
  position:; 
  bottom: 0; 
  width: 100%; 
} 
.sticky { 
  position: sticky; 
  margin-bottom: 0; 
  z-index: 100; 
} 

p { 
  text-align: center; 
} 

/*****************************/


.parent {
  /* border: 1px solid black; */
  /* margin: 1rem; */
  padding: 2rem 2rem;
  text-align: center;
  margin-top: -40px;
}
.child {
  display: inline-block;
  /* border: 1px solid red; */
  padding: 1rem 1rem;
  vertical-align: middle;
  text-align: center;
}

/** carousel css*/
.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 1rem;
  color: #fff;
  margin: 1rem 0;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}

